import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    AvailableDay,
    CalendarCard,
    CalendarWrapper,
    DayHeader,
    DaySquare,
    Header,
    SingleDay
} from './Calendar.styles';

import type { DateYMDString } from './types';

const getDaysOfMonth = (date: DateTime) => {
    const dayCount = date.daysInMonth ?? 31;
    const firstDayOfMonth = date.set({ day: 1 });
    const dayInWeek = firstDayOfMonth.weekday % 7;
    const emptyPrefix = new Array(dayInWeek).fill(null);
    let emptySuffix = [];
    if ((dayInWeek + dayCount) % 7 > 0) {
        emptySuffix = new Array(7 - ((dayInWeek + dayCount) % 7)).fill(null);
    }
    const days = [...Array(dayCount).keys()].map((day) =>
        date.set({ day: day + 1 })
    );
    return emptyPrefix.concat(days.concat(emptySuffix));
};

const sameMonth = (date1: DateTime, date2: DateTime) =>
    date1.year === date2.year && date1.month === date2.month;

const getAvailableDatesInMonth = (dates: DateTime[], date: DateTime) =>
    dates.filter((availableDate) => sameMonth(availableDate, date));

const isAvailableDate = (dates: DateTime[], date: DateTime) => {
    const datesInMonth = getAvailableDatesInMonth(dates, date);
    return datesInMonth
        .map((availableDate) => availableDate.day)
        .includes(date.day);
};

const findClosestDate = (sortedDates: DateTime[], date: DateTime) => {
    let datesInMonth = getAvailableDatesInMonth(sortedDates, date);
    let minDuration = Infinity;
    let closestDate = DateTime.local(1900, 1, 1);
    let referenceDate = date.set({});
    while (datesInMonth.length === 0) {
        referenceDate = referenceDate.plus({ month: 1 });
        datesInMonth = getAvailableDatesInMonth(sortedDates, referenceDate);
    }
    datesInMonth.forEach((availableDate) => {
        const duration = Math.abs(date.diff(availableDate).as('days'));
        if (duration < minDuration) {
            minDuration = duration;
            closestDate = availableDate;
        }
    });
    return closestDate;
};

const changeMonths = (
    directionForward: boolean,
    sortedDates: DateTime[],
    date: DateTime
) => {
    let newDate = date.set({});
    let index;
    if (sortedDates.length > 0) {
        index = sortedDates.findIndex((el) => date.equals(el));
        while (sameMonth(date, newDate)) {
            index = directionForward ? index + 1 : index - 1;
            newDate = sortedDates[index];
        }
    } else if (directionForward) {
        newDate = newDate.plus({ months: 1 });
    } else {
        newDate = newDate.minus({ months: 1 });
    }
    return newDate;
};

export default function Calendar(props: {
    date: DateYMDString;
    setDate: (date: DateYMDString) => void;
    availableDates: DateYMDString[];
}) {
    const { setDate: propsSetDate } = props;
    const date = useMemo(
        () => DateTime.fromFormat(props.date, 'yyyy-MM-dd'),
        [props.date]
    );
    const setDate = useCallback(
        (tempDate: DateTime) => {
            propsSetDate(tempDate.toFormat('yyyy-MM-dd') as DateYMDString);
        },
        [propsSetDate]
    );
    const [sortedDates, setSortedDates] = useState<DateTime[]>([]);

    useEffect(() => {
        const luxonDates = props.availableDates.map((tempDate) =>
            DateTime.fromFormat(tempDate, 'yyyy-MM-dd')
        );
        luxonDates.sort((a, b) => a.toUnixInteger() - b.toUnixInteger());
        setSortedDates(luxonDates);
    }, [props.availableDates]);

    useEffect(() => {
        if (sortedDates.length > 0) {
            setDate(findClosestDate(sortedDates, date).set({}));
        }
    }, [sortedDates, date, setDate]);

    const onArrowClick = (directionForward: boolean) => {
        setDate(changeMonths(directionForward, sortedDates, date).set({}));
    };

    return (
        <CalendarCard variant='outlined'>
            <Header>
                <Typography variant={'h2'}>
                    {date.toFormat('MMM yyyy')}
                </Typography>
                <div>
                    <IconButton
                        size='large'
                        disabled={date.hasSame(sortedDates[0], 'month')}
                        sx={{ color: 'text.primary' }}
                        onClick={() => {
                            onArrowClick(false);
                        }}
                        aria-label='previous month'
                    >
                        <KeyboardArrowLeftIcon fontSize='inherit' />
                    </IconButton>
                    <IconButton
                        size='large'
                        disabled={date.hasSame(
                            sortedDates[sortedDates.length - 1],
                            'month'
                        )}
                        sx={{ color: 'text.primary' }}
                        onClick={() => {
                            onArrowClick(true);
                        }}
                        aria-label='next month'
                    >
                        <KeyboardArrowRightIcon fontSize='inherit' />
                    </IconButton>
                </div>
            </Header>
            <CalendarWrapper>
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((key) => (
                    <DaySquare key={key}>
                        <DayHeader>
                            <Typography sx={{ fontWeight: 'medium' }}>
                                {key}
                            </Typography>
                        </DayHeader>
                    </DaySquare>
                ))}
                {getDaysOfMonth(date).map((day, index) => (
                    <DaySquare key={`square${index}`}>
                        {day && (
                            <SingleDay
                                disabled={
                                    sortedDates.length > 0 &&
                                    !isAvailableDate(sortedDates, day)
                                }
                                onClick={() => setDate(day)}
                            >
                                {isAvailableDate(sortedDates, day) ||
                                day.isSame(date) ? (
                                    <AvailableDay selected={day.isSame(date)}>
                                        <Typography fontWeight={700}>
                                            {day.date()}
                                        </Typography>
                                    </AvailableDay>
                                ) : (
                                    <Typography>{day.date()}</Typography>
                                )}
                            </SingleDay>
                        )}
                    </DaySquare>
                ))}
            </CalendarWrapper>
        </CalendarCard>
    );
}
