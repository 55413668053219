/* eslint-disable max-lines */
import MUIButton from '@mui/material/Button';
import MaterialModal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import {
    confirmDropAllMockData,
    connectMockCandidateToMockManager,
    createCandidateMocks,
    createCompanyMocks,
    createWaitlistCandidateMock,
    dropAllMockData,
    getMockStatus,
    getUserMockData,
    populateMockJobs
} from '../../../services/admin';
import Button from '../../global/components/Button/Button';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';

const content = { modalClose: 'Cancel' };

const StyledModal = styled(MaterialModal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
    max-height: 80%;
    overflow-y: scroll;
    min-width: 40rem;
`;

const StyledModalAction = styled('div')`
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
`;

const ModalButton = styled(Button)`
    flex: 1;
`;

const StyledRecursiveTable = styled('table')`
    border: 1px solid black;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    margin: 0.5rem auto;
    thead,
    tbody,
    tr,
    td,
    th {
        border: 1px solid black;
        padding: 0;
        margin: 0;
        border-spacing: 0;
    }
    td,
    th {
        padding: 0.5rem;
        text-align: center;
        vertical-align: middle;
    }
`;

type MockFunction =
    | 'mockUser'
    | 'mockCompany'
    | 'mockJob'
    | 'connectMockUser'
    | 'dropMockData'
    | 'mockWaitlistUser';

const mockFunctions = {
    mockUser: 'Mock User',
    mockCompany: 'Mock Company',
    mockJob: 'Populate Mock Jobs',
    connectMockUser: 'Connect Mock User to Mock Company',
    dropMockData: 'Drop Mock Data',
    mockWaitlistUser: 'Mock Waitlist User'
};

const RecursiveTable = ({ data }: { data: object }) => (
    <StyledRecursiveTable>
        <thead>
            <tr>
                <th>{'Key'}</th>
                <th>{'Value'}</th>
            </tr>
        </thead>
        <tbody>
            {data &&
                Object.entries(data).map(([key, value]) => (
                    <tr key={key}>
                        <td>{key}</td>
                        <td>
                            {typeof value === 'object' && value !== null ? (
                                <RecursiveTable data={value} />
                            ) : (
                                value.toString()
                            )}
                        </td>
                    </tr>
                ))}
        </tbody>
    </StyledRecursiveTable>
);

const Modal = (props: {
    heading: string;
    content: any;
    open: boolean;
    onClose: () => void;
    primaryCTAContent: string;
    primaryCTAAction: () => void;
    isLoading?: boolean;
}) => (
    <StyledModal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
        disableScrollLock
    >
        <StyledModalContent>
            <Typography
                variant={'h2'}
                id='modal-title'
                sx={{ fontWeight: 'bold' }}
            >
                {props.heading}
            </Typography>
            <div style={{ width: '100%', padding: '1rem' }}>
                {props.content}
            </div>

            <StyledModalAction>
                <ModalButton variant='outlined' onClick={props.onClose}>
                    {content.modalClose}
                </ModalButton>
                <ModalButton
                    id='modalPrimaryCTAAction'
                    variant='contained'
                    onClick={props.primaryCTAAction}
                    loading={props.isLoading}
                >
                    {props.primaryCTAContent}
                </ModalButton>
            </StyledModalAction>
        </StyledModalContent>
    </StyledModal>
);

export default function AdminPage() {
    const [mockFunction, setMockFunction] =
        useState<MockFunction>('connectMockUser');
    const [apiKey, setApiKey] = useState('');
    const [candidateID, setCandidateID] = useState('');
    const [managerID, setManagerID] = useState('');
    const [candidateEmail, setCandidateEmail] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [jobCount, setJobCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<any>(undefined);
    const [modal, setModal] = useState(false);
    const [trackStatus, setTrackStatus] = useState(false);

    useEffect(() => {
        const mockTypeMapping = {
            mockUser: 'candidate',
            mockCompany: 'manager',
            mockJob: 'jobs',
            connectMockUser: 'connection',
            dropMockData: 'drop',
            mockWaitlistUser: 'waitlist'
        };
        if (trackStatus && loading) {
            const intervalID = setInterval(() => {
                getMockStatus(mockTypeMapping[mockFunction], apiKey).then(
                    (response: any) => {
                        if (['success', 'failed'].includes(response.status)) {
                            setResult(response.data);
                            setLoading(false);
                            setTrackStatus(false);
                            clearInterval(intervalID);
                        }
                    }
                );
            }, 8000);
        }
    }, [apiKey, loading, mockFunction, trackStatus]);

    const submitRequest = () => {
        let requestPromise = Promise.resolve();
        setLoading(true);
        switch (mockFunction) {
            case 'mockUser':
                requestPromise = getUserMockData(candidateID, apiKey);
                setModal(true);
                break;
            case 'mockCompany':
                requestPromise = getUserMockData(managerID, apiKey);
                setModal(true);
                break;
            case 'dropMockData':
                requestPromise = confirmDropAllMockData(apiKey);
                setModal(true);
                break;
            case 'mockJob':
                requestPromise = populateMockJobs(jobCount, apiKey);
                break;
            case 'connectMockUser':
                requestPromise = connectMockCandidateToMockManager(
                    candidateID,
                    managerID,
                    candidateEmail,
                    managerEmail,
                    apiKey
                );
                break;
            case 'mockWaitlistUser':
                requestPromise = createWaitlistCandidateMock(apiKey);
                break;
            default:
                break;
        }
        requestPromise
            .then((response) => {
                if (['mockJob', 'connectMockUser'].includes(mockFunction)) {
                    setTrackStatus(true);
                } else {
                    setResult(response);
                    setLoading(false);
                }
            })
            .catch(() => setLoading(false));
    };

    const submitModalRequest = () => {
        let requestPromise = Promise.resolve();
        setLoading(true);
        switch (mockFunction) {
            case 'mockUser':
                requestPromise = createCandidateMocks(
                    candidateID,
                    candidateEmail,
                    apiKey
                );
                break;
            case 'mockCompany':
                requestPromise = createCompanyMocks(
                    managerID,
                    managerEmail,
                    apiKey
                );
                break;
            case 'dropMockData':
                requestPromise = dropAllMockData(apiKey);
                break;
            default:
                break;
        }
        requestPromise
            .then(() => {
                setTrackStatus(true);
            })
            .catch(() => setLoading(false));
    };

    return (
        <>
            <MainContainer
                sideBarButtons={[]}
                firstName={''}
                photoURL={''}
                signOut={() => {}}
            >
                <ColumnCard>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            gap: '1rem'
                        }}
                    >
                        <Typography
                            variant='h2'
                            component='h1'
                            fontWeight='400'
                            sx={{ mb: 2 }}
                        >
                            {'Mock User Generation'}
                        </Typography>
                        <Stack
                            direction='row'
                            flexWrap={'wrap'}
                            sx={{ gap: '0.5rem' }}
                        >
                            {Object.entries(mockFunctions).map(
                                ([key, value]) => (
                                    <MUIButton
                                        key={key}
                                        variant={
                                            key === mockFunction
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        sx={{
                                            textTransform: 'none',
                                            textWrap: 'nowrap'
                                        }}
                                        onClick={() =>
                                            setMockFunction(key as MockFunction)
                                        }
                                    >
                                        {value}
                                    </MUIButton>
                                )
                            )}
                        </Stack>
                        <TextField
                            label='API Key'
                            variant='outlined'
                            value={apiKey}
                            onChange={(e) => setApiKey(e.target.value)}
                        />
                        {['mockUser', 'connectMockUser'].includes(
                            mockFunction
                        ) && (
                            <>
                                <TextField
                                    label='Candidate ID'
                                    variant='outlined'
                                    value={candidateID}
                                    onChange={(e) =>
                                        setCandidateID(e.target.value)
                                    }
                                />
                                <TextField
                                    label='Candidate Email'
                                    variant='outlined'
                                    value={candidateEmail}
                                    onChange={(e) =>
                                        setCandidateEmail(e.target.value)
                                    }
                                />
                            </>
                        )}
                        {['mockCompany', 'connectMockUser'].includes(
                            mockFunction
                        ) && (
                            <>
                                <TextField
                                    label='Manager ID'
                                    variant='outlined'
                                    value={managerID}
                                    onChange={(e) =>
                                        setManagerID(e.target.value)
                                    }
                                />
                                <TextField
                                    label='Manager Email'
                                    variant='outlined'
                                    value={managerEmail}
                                    onChange={(e) =>
                                        setManagerEmail(e.target.value)
                                    }
                                />
                            </>
                        )}
                        {mockFunction === 'mockJob' && (
                            <TextField
                                label='Populate Jobs Count'
                                variant='outlined'
                                type={'number'}
                                value={jobCount}
                                onChange={(e) =>
                                    setJobCount(parseInt(e.target.value, 10))
                                }
                            />
                        )}
                        <Button
                            variant='contained'
                            onClick={submitRequest}
                            loading={loading}
                        >
                            {'Generate Mock Data'}
                        </Button>
                        {result?.signup_link && (
                            <div>
                                <p>{'Sign in details:'}</p>
                                <p>{`Email: ${result?.email}`}</p>
                                <p>{`Sign in link: ${result.signup_link}`}</p>
                            </div>
                        )}
                    </div>
                </ColumnCard>
            </MainContainer>
            <Modal
                heading={mockFunction}
                content={<RecursiveTable data={result} />}
                open={modal}
                onClose={() => setModal(false)}
                primaryCTAContent={'Submit'}
                primaryCTAAction={submitModalRequest}
                isLoading={loading}
            />
        </>
    );
}
