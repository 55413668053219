/* eslint-disable max-lines */
import { DateTime } from 'luxon';

import {
    convertLocalLuxonToUTCDateTime,
    convertUTCDateTimeToLocalLuxon
} from '../../utils/convertDate';

import { convertInterviewResponseToInterview } from './interview';

import type {
    NetworkConnection,
    NetworkConnectionResponse,
    ResumeResponse,
    User,
    UserResponse
} from '../../types/user';

export const createEmptyUser = (): User => {
    const user: User = {
        id: 0,
        userID: '',
        email: '',
        levylRole: 'candidate',
        createdAt: DateTime.now(),
        name: {
            firstName: '',
            lastName: '',
            currentJobTitle: '',
            pronouns: 'they/them',
            imageURL: ''
        },
        location: {
            location: '',
            workInCanada: false,
            termsAndConditions: false
        },
        idealRole: {
            desiredRoles: ['designer'],
            desiredLocations: ['toronto'],
            workingEnvironment: 'no_preference',
            desiredSalary: 25000
        },
        experience: [
            {
                title: '',
                company: '',
                startDate: DateTime.now(),
                endDate: DateTime.now(),
                description: ''
            }
        ],
        education: [
            {
                title: '',
                institution: '',
                startDate: DateTime.now(),
                endDate: DateTime.now()
            }
        ],
        certification: [
            { title: '', institution: '', endDate: DateTime.now() }
        ],
        skills: { hard: [''], soft: [''], languages: [''] },
        portfolio: [{ title: '', url: '' }],
        interestedQuestions: [
            {
                id: 0,
                userID: 0,
                question: '',
                systemGeneratedID: '',
                numberResponse: false
            },
            {
                id: 0,
                userID: 0,
                question: '',
                systemGeneratedID: '',
                numberResponse: false
            },
            {
                id: 0,
                userID: 0,
                question: '',
                systemGeneratedID: '',
                numberResponse: false
            }
        ],
        demographics: {
            gender: 'no_disclose',
            age: 0,
            ethnicity: 'no_disclose',
            disability: 'no_disclose',
            lgbtq: 'no_disclose'
        },
        yearsOfExperience: 0,
        highestLevelEducation: 'none',
        online: true,
        waitlist: false,
        roomID: '',
        meetingToken: ''
    };
    return user;
};

export const convertUserResponseToUser = (
    response: UserResponse
): User | null => {
    if (!response) return null;

    const user: User = {
        id: response.id,
        userID: response.user_id,
        email: response.email,
        levylRole: response.levyl_role,
        createdAt: convertUTCDateTimeToLocalLuxon(response.created_at),
        name: {
            firstName: response.first_name,
            lastName: response.last_name,
            currentJobTitle: response.current_job_title,
            pronouns: response.pronouns,
            imageURL: response.profile_picture_path
        },
        location: {
            location: response.current_location,
            workInCanada: response.work_in_canada,
            termsAndConditions: response.accept_terms
        },
        idealRole: {
            desiredRoles: response.interested_careers,
            desiredLocations: response.interested_locations,
            workingEnvironment: response.work_preference,
            desiredSalary: response.min_salary
        },
        experience: response.experience.map((experience) => ({
            title: experience.title,
            company: experience.company,
            startDate: convertUTCDateTimeToLocalLuxon(experience.start_date),
            endDate: convertUTCDateTimeToLocalLuxon(experience.end_date),
            description: experience.description
        })),
        education: response.education.map((education) => ({
            title: education.title,
            institution: education.institution,
            startDate: convertUTCDateTimeToLocalLuxon(education.start_date),
            endDate: convertUTCDateTimeToLocalLuxon(education.end_date)
        })),
        certification: response.certification.map((certification) => ({
            title: certification.title,
            institution: certification.institution,
            endDate: convertUTCDateTimeToLocalLuxon(certification.end_date)
        })),
        skills: {
            hard: response.hard_skills,
            soft: response.soft_skills,
            languages: response.languages
        },
        portfolio: response.portfolio,
        interestedQuestions: response.interested_questions.map((question) => ({
            id: question.id,
            userID: question.user_id,
            question: question.question,
            systemGeneratedID: question.system_generated_id,
            numberResponse: question.number_response
        })),
        demographics: {
            gender: response.gender,
            age: response.age,
            ethnicity: response.ethnicity,
            disability: response.disability,
            lgbtq: response.lgbtq
        },
        yearsOfExperience: response.years_of_experience,
        highestLevelEducation: response.highest_level_education,
        online: response.online,
        waitlist: response.waitlist,
        roomID: response.room_id,
        meetingToken: response.meeting_token
    };
    return user;
};

export const convertUserToUserResponse = (user: User): UserResponse => {
    const response: UserResponse = {
        id: user.id,
        user_id: user.userID,
        created_at: convertLocalLuxonToUTCDateTime(user.createdAt),
        first_name: user.name.firstName,
        last_name: user.name.lastName,
        email: user.email,
        current_job_title: user.name.currentJobTitle,
        current_location: user.location.location,
        pronouns: user.name.pronouns,
        work_in_canada: user.location.workInCanada,
        accept_terms: user.location.termsAndConditions,
        work_preference: user.idealRole.workingEnvironment,
        profile_picture_path: user.name.imageURL,
        portfolio: user.portfolio,
        min_salary: user.idealRole.desiredSalary,
        years_of_experience: user.yearsOfExperience,
        highest_level_education: user.highestLevelEducation,
        online: user.online,
        waitlist: user.waitlist,
        levyl_role: user.levylRole,
        room_id: user.roomID,
        meeting_token: user.meetingToken,
        gender: user.demographics.gender,
        age: user.demographics.age,
        ethnicity: user.demographics.ethnicity,
        disability: user.demographics.disability,
        lgbtq: user.demographics.lgbtq,
        interested_careers: user.idealRole.desiredRoles,
        interested_locations: user.idealRole.desiredLocations,
        hard_skills: user.skills.hard,
        soft_skills: user.skills.soft,
        languages: user.skills.languages,
        interested_questions: user.interestedQuestions.map((question) => ({
            id: question.id,
            user_id: question.userID,
            question: question.question,
            system_generated_id: question.systemGeneratedID,
            number_response: question.numberResponse
        })),
        experience: user.experience.map((experience) => ({
            title: experience.title,
            company: experience.company,
            start_date: convertLocalLuxonToUTCDateTime(experience.startDate),
            end_date: convertLocalLuxonToUTCDateTime(experience.endDate),
            description: experience.description
        })),
        education: user.education.map((education) => ({
            title: education.title,
            institution: education.institution,
            start_date: convertLocalLuxonToUTCDateTime(education.startDate),
            end_date: convertLocalLuxonToUTCDateTime(education.endDate)
        })),
        certification: user.certification.map((certification) => ({
            title: certification.title,
            institution: certification.institution,
            end_date: convertLocalLuxonToUTCDateTime(certification.endDate)
        }))
    };
    return response;
};

export const convertNetworkConnectionResponseToNetworkConnection = (
    response: NetworkConnectionResponse
): NetworkConnection => {
    const connection: NetworkConnection = {
        id: response.id,
        createdAt: convertUTCDateTimeToLocalLuxon(response.created_at),
        messageChatURL: response.message_chat_url,
        contact: convertUserResponseToUser(response.contact) as User,
        interviews: response.interviews.map((interview) =>
            convertInterviewResponseToInterview(interview)
        )
    };
    return connection;
};

export const convertParsedResumetoUserPartial = (
    response: ResumeResponse
): Partial<User> => {
    const userPartial: Partial<User> = {
        experience: response.experience.map((experience) => ({
            title: experience.role,
            company: experience.company,
            startDate: experience.start
                ? convertUTCDateTimeToLocalLuxon(experience.start)
                : DateTime.now(),
            endDate:
                !experience.end || experience.end.toLowerCase() === 'present'
                    ? DateTime.now()
                    : convertUTCDateTimeToLocalLuxon(experience.end),
            description: experience.description
        })),
        education: response.education.map((education) => ({
            title: education.title,
            institution: education.institution,
            startDate: education.start
                ? convertUTCDateTimeToLocalLuxon(education.start)
                : DateTime.now(),
            endDate:
                !education.end || education.end.toLowerCase() === 'present'
                    ? DateTime.now()
                    : convertUTCDateTimeToLocalLuxon(education.end)
        })),
        skills: {
            hard: response.skills.hard,
            soft: response.skills.soft,
            languages: []
        }
    };
    return userPartial;
};
