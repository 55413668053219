/* eslint-disable max-lines */
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { ProfilePicture } from '../../global/Core';
import { createFeedbackMap } from '../Interview/hooks/useInterviewFeedback';

import type { Interview } from '../../../types/interview';
import type { NetworkConnection } from '../../../types/user';

const StyledAccordion = styled(Accordion)(() => ({
    border: 'none',
    marginBottom: '1rem',
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    }
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    backgroundColor: '#F5F3FF',
    padding: '0.5rem 2rem 0.5rem 2rem',
    borderRadius: '0.5rem'
}));

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
    max-height: 90%;
    overflow-y: auto;
    max-width: 60rem;
`;

const StyledRatingQuestion = styled(Grid)`
    display: flex;
    justify-content: flex-start;
`;

const StyledRatingAnswer = styled(Grid)`
    display: flex;
    justify-content: flex-end;
    @media (max-width: 600px) {
        justify-content: flex-start;
    }
`;

export const StarRating = (props: { value: number }) => (
    <>
        {[1, 2, 3, 4, 5].map((position) => (
            <div key={position}>
                {(props.value - position >= 0.5 ||
                    props.value === position) && (
                    <StarIcon
                        fontSize='medium'
                        sx={{ color: 'text.primary' }}
                    />
                )}
                {position - props.value >= 1 && (
                    <StarOutlineIcon
                        fontSize='medium'
                        sx={{ color: 'text.primary' }}
                    />
                )}
                {position - props.value === 0.5 && (
                    <StarHalfIcon
                        fontSize='medium'
                        sx={{ color: 'text.primary' }}
                    />
                )}
            </div>
        ))}
    </>
);

const InterviewFeedback = (interview: Interview) => {
    const feedbackMap = createFeedbackMap(interview);
    const numericKeys = Object.keys(feedbackMap?.system || {}).filter(
        (key) => key !== 'feedback'
    );
    return (
        <div
            style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                border: '2px solid #848485',
                padding: '1rem',
                borderRadius: '0.5rem'
            }}
        >
            <Typography variant={'h2'} sx={{ mb: 2 }}>
                {`${interview.job.title}
                 - ${interview.startTime.toLocaleString(
                     DateTime.DATETIME_MED
                 )}`}
            </Typography>
            <Grid container spacing={2}>
                {numericKeys.map((key) => (
                    <>
                        <StyledRatingQuestion item xs={12} sm={6} key={key}>
                            <Typography variant={'body2'}>
                                {feedbackMap?.system[key].question.question}
                            </Typography>
                        </StyledRatingQuestion>
                        <StyledRatingAnswer item xs={12} sm={6} key={key}>
                            <StarRating
                                value={
                                    parseInt(
                                        feedbackMap?.system[key].answer
                                            ?.answer || '0',
                                        10
                                    ) || 0
                                }
                            />
                        </StyledRatingAnswer>
                    </>
                ))}
            </Grid>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '1rem'
                }}
            >
                <Typography variant={'h6'}>
                    {feedbackMap?.system.feedback.question.question}
                </Typography>
                <Typography variant={'body2'}>
                    <i>
                        {feedbackMap?.system.feedback.answer?.answer ??
                            'You did not provide feedback'}
                    </i>
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '1rem'
                }}
            >
                <Typography variant={'h5'}>
                    <b>{'User Requested Questions'}</b>
                </Typography>
                {Object.keys(feedbackMap?.user || {}).map((key) => (
                    <>
                        <Typography variant={'h6'} sx={{ mt: 1 }}>
                            {feedbackMap?.user[key].question.question}
                        </Typography>
                        <Typography variant={'body2'}>
                            <i>
                                {feedbackMap?.user[key].answer?.answer ??
                                    'You did not provide an answer'}
                            </i>
                        </Typography>
                    </>
                ))}
            </div>
        </div>
    );
};

export default function ProfileModal(props: {
    open: boolean;
    handleClose: () => void;
    connection: NetworkConnection;
}) {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { connection } = props;

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <StyledModal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <StyledModalContent>
                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                    <ProfilePicture
                        src={connection.contact.name.imageURL}
                        size={4}
                        style={{ marginRight: '1rem' }}
                    />
                    <div>
                        <Typography
                            style={{
                                marginTop: '0.5rem',
                                marginBottom: '0.25rem'
                            }}
                            variant={'body2'}
                        >
                            <strong>{`${connection.contact.name.firstName} ${connection.contact.name.lastName}`}</strong>
                        </Typography>
                        <Typography
                            style={{ marginBottom: '0.25rem' }}
                            variant={'body2'}
                        >{`${connection.contact.name.currentJobTitle}`}</Typography>
                    </div>
                </div>
                <StyledAccordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                >
                    <StyledAccordionSummary
                        expandIcon={
                            expanded === 'panel2' ? (
                                <RemoveIcon color='primary' />
                            ) : (
                                <AddIcon color='primary' />
                            )
                        }
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                flexShrink: 0,
                                fontWeight: 600
                            }}
                        >
                            {'Interview ratings'}
                        </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails style={{ padding: '1rem' }}>
                        {connection.interviews.map((interview, idx) => (
                            <InterviewFeedback key={idx} {...interview} />
                        ))}
                    </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                    expanded={expanded === 'panel3'}
                    onChange={handleChange('panel3')}
                >
                    <StyledAccordionSummary
                        expandIcon={
                            expanded === 'panel3' ? (
                                <RemoveIcon color='primary' />
                            ) : (
                                <AddIcon color='primary' />
                            )
                        }
                        aria-controls='panel3bh-content'
                        id='panel3bh-header'
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                flexShrink: 0,
                                fontWeight: 600
                            }}
                        >
                            {'Educational experience'}
                        </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails style={{ padding: '1rem' }}>
                        <Typography
                            variant={'body2'}
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {connection.contact.education.map((school, idx) => (
                                <div
                                    key={idx}
                                    style={{
                                        marginTop: '1rem',
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <Typography variant={'body2'}>
                                        <b>{school.title}</b>
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        {school.institution}
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        {school.startDate.toFormat(
                                            'yyyy-MM-dd'
                                        )}
                                        {' - '}
                                        {school.endDate.toFormat('yyyy-MM-dd')}
                                    </Typography>
                                </div>
                            ))}
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                    expanded={expanded === 'panel4'}
                    onChange={handleChange('panel4')}
                >
                    <StyledAccordionSummary
                        expandIcon={
                            expanded === 'panel4' ? (
                                <RemoveIcon color='primary' />
                            ) : (
                                <AddIcon color='primary' />
                            )
                        }
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                flexShrink: 0,
                                fontWeight: 600
                            }}
                        >
                            {'Work Experience'}
                        </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails style={{ padding: '1rem' }}>
                        <Typography
                            variant={'body2'}
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {connection.contact.experience.map((job, idx) => (
                                <div
                                    key={idx}
                                    style={{
                                        marginTop: '1rem',
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <Typography variant={'body2'}>
                                        <b>{job.title}</b>
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        {job.company}
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        {job.startDate.toFormat('yyyy-MM-dd')}
                                        {' - '}
                                        {job.endDate.toFormat('yyyy-MM-dd')}
                                    </Typography>
                                    <Typography
                                        style={{ marginTop: '1rem' }}
                                        variant={'body2'}
                                        dangerouslySetInnerHTML={{
                                            __html: job.description
                                        }}
                                    />
                                </div>
                            ))}
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
            </StyledModalContent>
        </StyledModal>
    );
}
