import {
    convertLocalLuxonToUTCDateTime,
    convertUTCDateTimeToLocalLuxon
} from '../../utils/convertDate';

import { convertCompanyResponseToCompany } from './company';
import { convertSimpleJobResponseToSimpleJob } from './job';
import { convertUserResponseToUser } from './user';

import type {
    CreateInterviewDetails,
    Interview,
    InterviewResponse,
    SimpleInterview,
    SimpleInterviewResponse
} from '../../types/interview';

// const createEmptySimpleInterview = (): SimpleInterview => {
//     const interview: SimpleInterview = {
//         id: 0,
//         jobID: 0,
//         interviewerID: 0,
//         startTime: '',
//         duration: 0,
//         createdAt: '',
//         sessionID: '',
//         candidateID: 0,
//         booked: false,
//         bookedAt: '',
//         candidateMeetingToken: '',
//         messageChatURL: '',
//         candidatePresent: false,
//         interviewerPresent: false,
//         completedDuration: 0,
//         status: 'available',
//         feedback: []
//     };
//     return interview;
// };

export const convertSimpleInterviewResponseToSimpleInterview = (
    response: SimpleInterviewResponse
): SimpleInterview => {
    const interview: SimpleInterview = {
        id: response.id,
        jobID: response.job_id,
        interviewerID: response.interviewer_id,
        startTime: convertUTCDateTimeToLocalLuxon(response.start_time),
        duration: response.duration,
        createdAt: convertUTCDateTimeToLocalLuxon(response.created_at),
        sessionID: response.session_id,
        candidateID: response.candidate_id,
        booked: response.booked,
        bookedAt: convertUTCDateTimeToLocalLuxon(response.booked_at),
        candidateMeetingToken: response.candidate_meeting_token,
        messageChatURL: response.message_chat_url,
        candidatePresent: response.candidate_present,
        interviewerPresent: response.interviewer_present,
        completedDuration: response.completed_duration,
        status: response.status,
        feedback: response.feedback.map((feedback) => ({
            question: {
                id: feedback.question.id,
                userID: feedback.question.user_id,
                question: feedback.question.question,
                systemGeneratedID: feedback.question.system_generated_id,
                numberResponse: feedback.question.number_response
            },
            answer: feedback.answer
                ? {
                      id: feedback.answer.id,
                      questionID: feedback.answer.question_id,
                      interviewID: feedback.answer.interview_id,
                      answer: feedback.answer.answer,
                      answeredByCandidate: feedback.answer.answered_by_candidate
                  }
                : feedback.answer
        }))
    };
    return interview;
};

// const convertSimpleInterviewToSimpleInterviewResponse = (
//     interview: SimpleInterview
// ): SimpleInterviewResponse => {
//     const response: SimpleInterviewResponse = {
//         id: interview.id,
//         job_id: interview.jobID,
//         interviewer_id: interview.interviewerID,
//         start_time: interview.startTime,
//         duration: interview.duration,
//         created_at: interview.createdAt,
//         session_id: interview.sessionID,
//         candidate_id: interview.candidateID,
//         booked: interview.booked,
//         booked_at: interview.bookedAt,
//         candidate_meeting_token: interview.candidateMeetingToken,
//         message_chat_url: interview.messageChatURL,
//         candidate_present: interview.candidatePresent,
//         interviewer_present: interview.interviewerPresent,
//         completed_duration: interview.completedDuration,
//         status: interview.status,
//         feedback: interview.feedback.map((feedback) => ({
//             id: feedback.id,
//             question_id: feedback.questionID,
//             interview_id: feedback.interviewID,
//             answer: feedback.answer,
//             answered_by_candidate: feedback.answeredByCandidate
//         }))
//     };
//     return response;
// };

export const convertInterviewResponseToInterview = (
    response: InterviewResponse
): Interview => {
    const interview: Interview = {
        ...convertSimpleInterviewResponseToSimpleInterview(response),
        participant: response.participant
            ? convertUserResponseToUser(response.participant)
            : null,
        company: convertCompanyResponseToCompany(response.company),
        job: convertSimpleJobResponseToSimpleJob(response.job)
    };
    return interview;
};

export const convertCreateInterviewDetailsToCreateInterviewDetailsRequest = (
    interview: CreateInterviewDetails,
    jobID: number
) => {
    const interviewDetails = interview.details.map((detail) => ({
        duration: detail.duration.toString(),
        start_time: convertLocalLuxonToUTCDateTime(detail.startTime)
    }));
    return {
        job_id: jobID,
        interviewer_id: interview.interviewerID,
        split: interview.split,
        split_time: interview.splitTime,
        details: interviewDetails
    };
};
